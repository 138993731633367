import React from "react";
import { graphql } from "gatsby";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { AcfComponent } from "./componentLoader";
import { ImageAndContentBlock } from "../components/Acf/ImageAndContentBlock";
import { ProjectOverview } from "../components/Acf/ProjectOverview";

const ProjectPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings, categories } = data;

  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    project_category,
    acf,
  } = post;

  const layout = acf?.layout || [];

  const bannerImage =
    layout.find((field) => field.__typename.includes("postInformation"))
      ?.bannerImage || featured_media;


  const defaultProjectFacts = [];

  if (acf?.project_client) {
    defaultProjectFacts.push({
      label: 'Client',
      content: acf?.project_client
    });
  }

  project_category.map((category) => {
    let thisCategory = categories.nodes.find((categoryNode) => categoryNode.wordpress_id === category);
    let thisCategoryParent = categories.nodes.find((categoryNode) => categoryNode.wordpress_id === thisCategory.wordpress_parent);
    if (thisCategoryParent?.name) {
      defaultProjectFacts.push({
        label: thisCategoryParent?.name,
        content: thisCategory?.name
      });
    }
  });

  if (acf?.project_location) {
    defaultProjectFacts.push({
      label: 'Location',
      content: acf?.project_location
    });
  }

  if (acf?.project_scale) {
    defaultProjectFacts.push({
      label: 'Scale',
      content: acf?.project_scale
    });
  }

  if (acf?.year_of_completion) {
    defaultProjectFacts.push({
      label: 'Year of Completion',
      content: acf?.year_of_completion
    });
  }

  if (acf?.project_pdf?.source_url) {
    defaultProjectFacts.push({
      label: 'Download PDF',
      content: acf?.project_pdf?.source_url
    });
  }

  const defaultContent = {
    projectIntro: content,
    projectFacts: defaultProjectFacts
  };

  const hideDefaults = layout.find((layoutItem) => layoutItem.__typename.includes("WordPressAcf_ProjectOverview"));

  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={bannerImage?.source_url}
        location={location}
      />
      <Header header={title} image={bannerImage} compact />

      {!hideDefaults &&
        <ProjectOverview
          projectIntro={defaultContent.projectIntro}
          projectFacts={defaultContent.projectFacts}
        />
      }

      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          return (
            <AcfComponent
              key={index}
              index={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
            />
          );
        })}

    </>
  );
};

export default Previewable(ProjectPost, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
      query ProjectPostByID($id: String!) {
        wordpressWpSettings {
        title
      wordpressUrl
      siteUrl
    }
      siteSettings: wordpressAcfOptions {
        options {
        customCss
      }
    }
      categories: allWordpressWpProjectCategory {
        nodes {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
        }
      }
      wordpressPost: wordpressWpProjects(id: {eq: $id }) {
        id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        source_url
        localFile {
        childImageSharp {
        fluid(maxWidth: 1100, quality: 80) {
        ...GatsbyImageSharpFluid_noBase64
      }
          }
        }
      }
      acf {
        project_client
        project_pdf {
          source_url
          title
        }
        project_location
        project_scale
        year_of_completion
        layout: layout_projects {
        __typename
          ...PostInformationQuery
          ...FlexibleBlockQuery
          ...HeaderQuery
          ...BlogFeedQuery
          ...StaffProfilesQuery
          ...FullWidthImageContainedQuery
          ...FullWidthImageQuery
          ...ImageAndContentBlockQuery
          ...ContentAndIconListQuery
          ...TwoCardBlockQuery
          ...TwoColumnQuery
          ...ProjectOverviewQuery
          ...FullWidthCarouselQuery
          ...AccordionQuery
          ...TestimonialBlockQuery
        }
      }
      project_category
      yoast {
        metaTitle: title
      metaDescription: metadesc
      opengraph_image {
        source_url
      }
      twitter_image {
        source_url
      }
      }
    }
  }
`;
